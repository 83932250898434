<!-- 充值弹框 -->
<template>
  <div class="recharge-dialog">
    <myModal
      class="recharge-modal"
      :width="'17rem'"
      :visible="visible"
      @close="close($event)"
    >
      <template #cuscloseIcon>
        <img src="@/assets/images/systemicon/close.png" alt="" />
      </template>
      <template #body>
        <div class="recharge-container">
          <div class="left">
            <div class="title">
              <img class="picon" src="@/assets/images/recharge/rechargeicon.png" alt="" />
              <span>{{ $t("rechargeBalance") }}</span>
            </div>
            <div class="line"></div>
            <div class="list-container">
              <div class="list-title">{{ $t("paymentMethods") }}</div>
              <div class="list-cards">
                <div
                  class="card-item"
                  :class="{ 'active-card': citem.id === activeCard.id }"
                  v-for="citem in paymentList"
                  :key="citem.id"
                >
                  <div class="card" @click="selectCard(citem)">
                    <img class="card-icon" :src="citem.icon" alt="" />
                    <!-- <span>{{ citem.name + ":" }}</span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <!-- 银联 -->
            <div v-if="activeCard.type == 3">
              <div class="bank-container">
                <div class="email">
                  <div class="title">Email</div>
                  <div class="input-container single-input">
                    <a-input type="text" placeholder="请输入邮箱" />
                    <a-button>{{ $t("save") }}</a-button>
                  </div>
                </div>
                <div class="amount">
                  <div class="amountleft">
                    <div class="title acol">Amount</div>
                    <div class="input-container acol">
                      <a-input type="number" :placeholder="$t('inputRechargeBean')" />
                    </div>
                    <div class="price-list acol">
                      <a-button v-for="i in 4" :key="i">{{ "$ " + i }}</a-button>
                    </div>
                  </div>
                  <div class="amountright">
                    <div class="title acol">You get</div>
                    <div class="price acol">$25.00+$1.25</div>
                    <div class="info acol">
                      Minimum top-up amount <span class="balance">2.00</span>
                    </div>
                    <div class="pay-btn acol">
                      <a-button @click="handlePay">PAY</a-button>
                    </div>
                  </div>
                </div>
                <div class="promocode">
                  <div class="title col">{{ $t("promocode") }}</div>
                  <div class="input-container col">
                    <a-input type="text" :placeholder="$t('')" />
                    <a-button>SAVE</a-button>
                  </div>
                </div>
              </div>
            </div>

            <!-- 虚拟货币 -->
            <div v-if="activeCard.type == 'nowpayments'">
              <div class="virtual-container">
                <template v-if="!currencyDetail.isShow">
                  <div class="title">{{ $t("chooseAmount") }}</div>
                  <div class="gift-list">
                    <div
                      class="gift"
                      v-for="cardItem in activeCard.beansList"
                      :key="cardItem.id"
                      :class="{ 'active-gift': cardItem.id === currencyDetail.id }"
                      @click="selectCurrency(cardItem)"
                    >
                      <span class="gift-price">{{ Math.trunc(cardItem.price) }}</span>
                    </div>
                  </div>
                  <div class="title">{{ $t("chooseCryptocurrency") }}</div>
                  <div class="v-coins">
                    <!-- <div
                      class="v-coin"
                      v-for="bean in activeCard.beansList"
                      :key="bean.id"
                    >
                      <div class="v-img">
                        <img src="@/assets/images/recharge/vbiicon.png" alt="" />
                      </div>
                      <div>Bitcoin</div>
                      <div>BTC</div>
                    </div> -->
                    <div
                      class="v-coin"
                      v-for="dItem in activeCard.digitalList"
                      :key="dItem.id"
                      v-debounce:500="()=>handleDigitalCoin(dItem)"
                    >
                      <div class="v-img">
                        <img :src="dItem.icon" alt="" />
                      </div>
                      <div class="v-name">{{ dItem.name }}</div>
                      <div class="v-alias">{{ dItem.alias }}</div>
                    </div>
                  </div>
                  <div class="promocode">
                    <div class="title col">{{ $t("promocode") }}</div>
                    <div class="input-container col">
                      <a-input
                        v-model:value="currencyDetail.promoCode"
                        type="text"
                        :placeholder="$t('inputPromocode')"
                      />
                      <a-button
                        :class="btnIsAble(currencyDetail.promoCode)"
                        :disabled="!currencyDetail.promoCode"
                        >{{ $t("addL") }}</a-button
                      >
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="coin-container">
                    <div class="coin-left">
                      <div class="back" @click="trackSource">
                        <a-space>
                          <left-outlined />
                        </a-space>
                        {{ $t("comeBackShort") }}
                      </div>
                      <div class="qrcode" v-if="currencyDetail.resp.qr_url">
                        <vue-qr
                          :text="currencyDetail.resp.qr_url"
                          :margin="10"
                          :size="200"
                        ></vue-qr>
                      </div>
                      <div class="pay-info">
                        <span class="error">{{ $t("note") }}</span>
                        {{ $t("noticePayInfo") }}
                      </div>
                      <div class="require-info">
                        {{ currencyDetail.resp.pay_amount }}
                      </div>
                    </div>
                    <div class="coin-right">
                      <div class="title">
                        <img :src="currencyDetail.data.icon" alt="" />
                        <span class="coin-name">{{ currencyDetail.data.name }}</span>
                      </div>
                      <div class="content">
                        <div class="t1">{{ $t("copyAddress") }}</div>
                        <div class="code-link">
                          <span class="gspan">{{ currencyDetail.resp.qr_url }}</span>
                          <img
                            @click="copyPromotionLink(currencyDetail.resp.qr_url)"
                            class="copy-icon"
                            src="@/assets/images/recharge/copyicon.png"
                            alt=""
                          />
                        </div>
                        <div class="info">
                          <img src="@/assets/images/recharge/info.png" alt="" />
                          <span>{{ $t("depositingAddressInfo") }} </span>
                        </div>
                        <div class="title2">{{ $t("currentExchangeRate") }}</div>
                        <div class="code-link">
                          <span
                            >{{ currencyDetail.estimate.amount_from
                            }}{{ currencyDetail.estimate.currency_from||0 }}=</span
                          >
                          <span class="balance">{{
                            currencyDetail.estimate.estimated_amount||0
                          }}</span>
                        </div>
                        <div class="message">
                          <div class="m1">
                            <img src="@/assets/images/recharge/infored.png" alt="" />
                            {{ $t("beforePayment") }}
                          </div>
                          <div class="mt">
                            {{
                              $t("beforePaymentInfo", { currency: currencyDetail.alias })
                            }}
                          </div>
                          <div class="mt">
                            <span class="note">{{ $t("note") }}</span>
                            {{ $t("rateInfo") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <!-- 礼品卡 -->
            <div v-if="activeCard.type == 'giftcard'">
              <div class="gift-container">
                <div class="gift-list">
                  <div
                    class="gift"
                    v-for="cardItem in activeCard.beansList"
                    :key="cardItem.id"
                    @click="handleGiftCard(cardItem.card_link)"
                  >
                    <img :src="giftCardImg(cardItem.id)" alt="" />
                    <span class="gift-price">{{ Math.trunc(cardItem.price) }}</span>
                  </div>
                </div>
                <div class="gift-info">
                  {{ $t("giftCardInfo") }}
                </div>
                <div class="promocode">
                  <div class="title col">{{ $t("useGiftCard") }}</div>
                  <div class="input-container single-input col">
                    <a-input
                      type="text"
                      :placeholder="$t('inputPromocode')"
                      v-model:value="cardCode"
                    />
                    <a-button
                      :class="btnIsAble(cardCode)"
                      :disabled="!cardCode"
                      @click="handleKaMiPay"
                      >{{ $t("rechargeBtnUse") }}</a-button
                    >
                  </div>
                </div>
                <!-- <div class="promocode">
                  <div class="title col">Promocode</div>
                  <div class="input-container col">
                    <a-input type="text" placeholder="$t('inputPromocode')" />
                    <a-button>ADD</a-button>
                  </div>
                </div> -->
              </div>
            </div>

            <!-- skrill   skinsback -->
            <div v-if="activeCard.type == 'skinsback'">
              <div class="skinsback-container">
                <div class="block">
                  <div class="info">
                    {{$t('minPayBeans')}} <span class="balance">2.00</span>
                  </div>
                  <div class="btn" @click="handlePayForSkinsBack">
                    <a-button>{{ $t('btnCheckout') }}</a-button>
                  </div>
                </div>
                <div class="promocode">
                  <div class="title col">{{ $t("promocode") }}</div>
                  <div class="input-container col">
                    <a-input type="text"
                    v-model:value="skinsBackCode"
                     :placeholder="$t('inputPromocode')" />
                    <!-- <a-button>{{ $t("addL") }}</a-button> -->
                    
                    <a-button
                        :class="btnIsAble(skinsBackCode)"
                        :disabled="!skinsBackCode"
                        >{{ $t("addL") }}</a-button
                      >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </myModal>

    <!-- 充值结果提示弹框 -->
    <myModal
      class="recharge-result-modal"
      :visible="resultVisible"
      @close="closeResult($event)"
    >
      <template #cuscloseIcon>
        <img src="@/assets/images/systemicon/close.png" alt="" />
      </template>
      <template #body>
        <div class="tips">TIPS</div>
        <div class="content">
          <div class="info">
            <img :src="resultImg" alt="" />
            <div class="infos">
              {{ $t(resultInfo) }}
            </div>
          </div>
          <a-button class="result-btn" @click="closeResult($event)">{{
            resultBtnTxt
          }}</a-button>
        </div>
      </template>
    </myModal>
  </div>
</template>

<script setup>
import { LeftOutlined } from "@ant-design/icons-vue";
import { ref, computed, watch } from "vue";
import eventEmitter from "@/utils/emitter.js";
import { getImageUrlByNewURL } from "@/utils/getImageUrl.js";
import showNotice from "@/components/common/notice/notice.js";
import $api from "@/api";
import i18n from "@/i18n";
import vueQr from "vue-qr/src/packages/vue-qr.vue";
const { t: $t, locale } = i18n.global;
import useClipboard from "vue-clipboard3";
import handleResponse from "@/utils/handleResponseData.js";
const { toClipboard } = useClipboard();
import $store from "@/store";
const userStore = $store("user");
console.log("userStore:", userStore);
const copyPromotionLink = async (targetVal) => {
  //js 复制值到剪切板
  console.log("要复制的目标值:", targetVal);

  try {
    await toClipboard(targetVal);
    console.log("Copied to clipboard");
    showNotice("s", {
      desc: "复制成功",
    });
  } catch (e) {
    console.log(e);
  }
};

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["closed", "api:pay"]);

//当前选中的支付方式
const activeCard = ref({ id: 1, type: "giftcard", beansList: [], digitalList: [] });
const giftCardImg = computed(() => {
  //此处不能用proxy.$getImageUrlByNewURL 是因为充值组件是另外一个vue实例,就是说createApp render新创建的vue应用程序,跟main.js的vue app不是同一个实例,所以不能用proxy.$getImageUrlByNewURL,除非在创建充值弹框的地方全局添加属性
  return (index) => {
    return getImageUrlByNewURL("recharge/card" + index + ".png");
  };
});

//选择支付方式
function selectCard(item) {
  if (item.id == activeCard.value.id) {
    return;
  }
  resetcurrencyDetail();
  activeCard.value.beansList = [];
  console.log("item;", item);
  // activeCard.value.id = item.id;
  // activeCard.value.type = item.type;
  activeCard.value = item;
  //根据channel 显示对应支付方式的金币列表
  if (item.type == "giftcard") {
    //卡密支付方式写死,其余支付方式动态渲染
    getBeansList("giftcard");
  } else {
    if (item.type == "nowpayments") {
      getPaymentList().then((res) => {
        //数字货币1列表
        activeCard.value.digitalList = digitalList1;
        console.log('res;',res);
        console.log('list;',activeCard.value.digitalList);
        // activeCard.value.beansList = [];
        currencyDetail.value.pay_way = res.data[0].pay_way;//res.data[0]为nowpay;res.data[1]为skinpay
        getBeansList(res.data[0].channel);
      });
    } else {
      //其他支付方式动态渲染
      // getBeansList(item.channel);
    }
  }
}

//选择指定支付方式的金币列表
function getBeansList(channel) {
  if (!props.visible) {
    return;
  }
  console.log("showCoins", channel);
  $api.user.getBeansList({ params: { channel } }).then((res) => {
    console.log("getBeansList", res);
    activeCard.value.beansList = res.data;
    // currencyDetail.value.pay_way = res.data[0].pay_way;
    currencyDetail.value.id = activeCard.value.beansList[0].id;
  });
}

//点击卡密列表跳转卡密平台
function handleGiftCard(link) {
  window.open(link);
}

//卡密充值-礼品卡
const cardCode = ref("");
//点击使用卡密支付
function handleKaMiPay() {
  console.log("卡密充值");
  $api.user.handleKaMiRecharge({ card: cardCode.value }).then((res) => {
    console.log("卡密充值成功", res);
    if (res.code == 200) {
      showNotice("s", {
        desc: res.message,
      });
      //关闭充值弹框
      close();
      userStore.getUserInfo().then((res) => {
        console.log("登录成功,更新用户数据;", res);
      });
    } else {
      showNotice("f", {
        desc: res.message,
      });
    }
  });
}

//skinsback充值
const skinsBackCode = ref("");//skinsback充值码
function handleSkinsback() {
  console.log("skinsback充值");
}




const btnIsAble = computed(() => {
  return (code) => {
    return code ? "able-btn" : "disable-btn";
  };
});

//虚拟币处理对象
const currencyDetail = ref({
  isShow: false,//默认显示虚拟货币一级页面,为true显示虚拟货币充值汇率详情页面
  id: -1, //金币充值列表 虚拟币当前选中的充值金额id
  pay_way: -1, //充值方式
  alias: "", //币种-BTN DOGE等
  data: [],//点击选中的虚拟货币对象数据
  resp: {},//当前选中虚拟货币充值返回数据
  estimate: {},//汇率接口返回数据
  promoCode: "", //优惠码
});

//重置虚拟币处理对象
function resetcurrencyDetail(isReset = true) {
  currencyDetail.value.isShow = false;
  if(isReset){
    currencyDetail.value.id = -1; //金币充值列表 虚拟币当前选中的充值金额id
    currencyDetail.value.pay_way = -1; //充值方式
  }
  currencyDetail.value.data = [];
  currencyDetail.value.resp = {};
  currencyDetail.value.estimate = {};
  currencyDetail.value.promoCode = ""; //优惠码
}

//虚拟币选择要充值的档位
function selectCurrency(item) {
  currencyDetail.value.id = item.id;
}

//监听充值订单状态是否成功
function payStatus(ordercode) {
  payTimer.value = setInterval(() => {
    if (paySuccess.value) {
      $api.user.beanDetail({ params: { code: ordercode } }).then((res) => {
        console.log("充值订单详情", res);
        //若充值成功 弹框提示用户充值已到账,同时用户余额更新
        if (res.code == 200) {
          // if (res.code == 1) {
          //   //表示充值成功
          //   //弹框提示用户充值成功
          //   //用户余额更新
          // }
          if (res.data.status == 1) {
            showNotice("s", {
              desc: $t("paySuccess"),
            });
            console.log("充值已到账,更新余额");

            paySuccess.value = false;
            userStore.getUserInfo().then((res) => {
              console.log("登录成功,更新用户数据;", res);
            });
            console.log("用户余额已更新,清除定时器");
            clearInterval(payTimer.value);
            clearInterval(indateTimer.value);
            console.log("定时器清除完毕");
            //关闭充值弹框
            close();
          }
          console.log("测试监听订单定时器编号 timer:", payTimer.value);
        } else {
          showNotice("f", {
            desc: res.message,
          });
          clearInterval(payTimer.value);
          clearInterval(indateTimer.value);
        }
      });
    }
  }, 3000);
}

const payTimer = ref(null); //监听订单定时器
const indateTimer = ref(); //监听过期订单定时器
const paySuccess = ref(true); //支付成功确认

//数字货币充值
function handleDigitalCoin(item) {
  console.log("handleDigitalCoin", item);
  if (currencyDetail.value.id == -1) {
    return showNotice("f", { desc: "请选择充值金额" });
  }
  currencyDetail.value.alias = item.alias;
  //调用充值接口-生成链接和二维码
  const params = {
    currency: item.currency,
    pay_way: currencyDetail.value.pay_way, //充值方式
    id: currencyDetail.value.id, //充值金额id
  };
  console.log('params;',params);
  // return;
  $api.user.handleRecharge(params).then((res) => {
    console.log("handleRecharge", res);
    const html = res.html;
    const out_trade_no = res.out_trade_no;
    // payStatus(out_trade_no); //传入当前充值订单号

    //监听订单是否过期 -每隔20s生成新的二维码
    //当前时间戳(保留10  保留到秒) 加20s
    //20s+当前时间戳传给qrloose indate
    // Code参数:订单号  indate参数:过期时间(10位时间戳,到秒)
    // 返回支付链接转成二维码
    indateTimer.value = setInterval(() => {
      const timeC = Math.floor(Date.now() / 1000) + 20;
      const newUrl = `${
        import.meta.env.VITE_API_BASE_URL
      }/qrLoose?code=${out_trade_no}&indate=${timeC}`;
      // currencyDetail.value.resp.qr_url = newUrl;
      console.log("20s重新生成", newUrl);
    }, 1000 * 20);

    const type = res.type;
    currencyDetail.value.resp.qr_url = html.data.qr_url;
    currencyDetail.value.resp.pay_amount = html.data.pay_amount;
    currencyDetail.value.resp.out_trade_no = out_trade_no;
    currencyDetail.value.resp.type = type;

    currencyDetail.value.isShow = true;
    currencyDetail.value.data = item;

    //调用汇率接口
    const params2 = {
      amount: 1,
      currency_from: item.alias,
      currency_to: "usd",
    };
    console.log("汇率参数:", params2);
    $api.user.getEstimate(params2).then((res) => {
      console.log("汇率接口返回;",res);
      currencyDetail.value.estimate = res.data;
      //js 字符串转大写
      currencyDetail.value.estimate.currency_from = (currencyDetail.value.estimate?.currency_from?.toUpperCase())||0;
    });
  });
}

//skinsback支付
function handlePayForSkinsBack(){
  console.log('skinsback支付');
  const params = {
    id:1,
    pay_way:2,
    coupon_code:skinsBackCode.value||undefined
  }
  $api.user.handleRecharge(params).then((res) => {
    console.log('skinsback res;',res);
    if(res.code!=200){
      return showNotice('f',{desc:res.message});
    }
    const out_trade_no = res.out_trade_no;
    const url = res.list.qr_url
    window.open(url);
  })

  // window.open('http://www.baidu.com', '_blank');
}

//溯源-返回虚拟货币列表
function trackSource() {
  currencyDetail.value.isShow = false;
  currencyDetail.value.data = [];
   //清除定时器
   clearInterval(payTimer.value);//清除订单定时器
  clearInterval(indateTimer.value);//清除过期订单定时器
  //重置虚拟币处理对象
  resetcurrencyDetail(false);
  console.log('返回;',currencyDetail.value);
}

//关闭充值弹框
function close(e) {
  console.log("关闭充值弹框;", e);
  //清除定时器
  clearInterval(payTimer.value);
  clearInterval(indateTimer.value);
  //重置虚拟币处理对象
  resetcurrencyDetail();
  //关闭弹框
  emit("closed");
}

//银联充值处理
function handlePay() {
  // eventEmitter.emit("rechargePay");
  console.log("handlePay 银联充值处理");
  const result = Promise.resolve("充值成功");
  result
    .then((res) => {
      console.log("充值成功", res);
      emit("api:pay", res);
    })
    .catch((err) => {
      console.log("充值失败", err);
    });
  return result;
}

//获取支付列表
function getPaymentList() {
  return $api.user.getPaymentList({ params: { client_type: "Pc" } });

  // .then((res) => {
  //   console.log("获取支付列表", res);
  //   res.data.forEach((item) => {
  //     payList.forEach((pay) => {
  //       if (pay.type === item.type) {
  //         for (let key in item) {
  //           if (key != "type") {
  //             pay[key] = item[key];
  //           }
  //         }
  //       }
  //     });
  //   });
  //   console.log("payList2;", payList);
  // });
}

//默认显示礼品卡
getBeansList(activeCard.value.type);

// getPaymentList().then((res) => {
//   console.log("获取支付列表成功,默认显示卡密;", res);
//   //默认显示礼品卡
//   activeCard.value = { id: 1, type: "giftcard", beansList: [] };
//   getBeansList(activeCard.value.type);
// });

//数字货币1列表-如何实现动态导入图片模块
const digitalList1 = [
  {
    id: 1,
    name: "Bitcoin",
    alias: "BTC",
    currency: "btc",
    icon: getImageUrlByNewURL("recharge/digital1/1.png"),
  },
  {
    id: 2,
    name: "Ethereum",
    alias: "ERC20",
    currency: "eth",
    icon: getImageUrlByNewURL("recharge/digital1/2.png"),
  },
  {
    id: 3,
    name: "Tether US",
    alias: "ERC20",
    currency: "usdterc20",
    icon: getImageUrlByNewURL("recharge/digital1/3.png"),
  },
  {
    id: 4,
    name: "Tether US",
    alias: "TRC20",
    currency: "usdttrc20",
    icon: getImageUrlByNewURL("recharge/digital1/4.png"),
  },
  {
    id: 5,
    name: "USD Coin",
    alias: "USDC",
    currency: "usdc",
    icon: getImageUrlByNewURL("recharge/digital1/5.png"),
  },
  {
    id: 6,
    name: "Solana",
    alias: "SOL",
    currency: "sol",
    icon: getImageUrlByNewURL("recharge/digital1/6.png"),
  },
  {
    id: 7,
    name: "Ripple",
    alias: "XRP",
    currency: "xrp",
    icon: getImageUrlByNewURL("recharge/digital1/7.png"),
  },
  {
    id: 8,
    name: "Dogecoin",
    alias: "DOGE",
    currency: "doge",
    icon: getImageUrlByNewURL("recharge/digital1/8.png"),
  },
  {
    id: 9,
    name: "Tron",
    alias: "TRC20",
    currency: "trx",
    icon: getImageUrlByNewURL("recharge/digital1/9.png"),
  },
  {
    id: 10,
    name: "Litecoin",
    alias: "LTC",
    currency: "ltc",
    icon: getImageUrlByNewURL("recharge/digital1/10.png"),
  },
  {
    id: 11,
    name: "Bitcoin Cash Node",
    alias: "BTC",
    currency: "bch",
    icon: getImageUrlByNewURL("recharge/digital1/11.png"),
  },
  // {
  //   id: 12,
  //   name: "Other 130+ coins",
  //   alias: "",
  //   icon: getImageUrlByNewURL("recharge/digital1/12.png"),
  // },
];
const paymentList = ref([
  {
    id: 1,
    name: "礼品卡",
    type: "giftcard",
    // icon: "@/assets/images/recharge/payCard.png",
    icon: getImageUrlByNewURL("recharge/payCard.png"),
  },
  {
    id: 2,
    name: "皮肤充值-skinback",
    type: "skinsback",
    // icon: "@/assets/images/recharge/pskin.png",
    icon: getImageUrlByNewURL("recharge/pskin.png"),
  },
  // {
  //   id: 3,
  //   name: "数字货币1-whitepay",
  //   type: "whitepay",
  //   // icon: "@/assets/images/recharge/digital1.png",
  //   icon: getImageUrlByNewURL("recharge/digital1.png"),
  //   digitalList: digitalList1,
  // },
  {
    id: 4,
    name: "数字货币2-nowpayments",
    type: "nowpayments",
    // icon: "@/assets/images/recharge/digital2.png",
    icon: getImageUrlByNewURL("recharge/digital2.png"),
  },
]);

//充值方式数组
const payList = [
  {
    id: 1,
    name: "支付宝",
    type: "alipay",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 2,
    name: "微信",
    type: "wechat",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 3,
    name: "银行卡",
    type: "bank",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 4,
    name: "礼品卡-卡密",
    type: "giftcard",
    // type: "currency",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 5,
    name: "PayPal",
    type: "paypal",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 6,
    name: "Apple Pay",
    type: "applepay",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 7,
    name: "Google Pay",
    type: "googlepay",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 8,
    name: "Venmo",
    type: "venmo",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 9,
    name: "Payoneer",
    type: "payoneer",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 10,
    name: "Bitcoin",
    type: "bitcoin",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 11,
    name: "skrill",
    type: "skrill",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 12,
    name: "虚拟货币",
    type: "currency",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 13,
    name: "Payeer",
    type: "payeer",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 14,
    name: "Qiwi",
    type: "qiwi",
    icon: "@/assets/images/recharge/pay1.png",
  },
];

//充值结果弹框
const resultVisible = ref(false);
function closeResult() {
  // return;
  resultVisible.value = false;
}
const resultBtnTxt = ref("OK"); //TRY AGAIN
const resultInfo = ref("payResultSuccessInfo");
const resultImg = ref(getImageUrlByNewURL("systemicon/notice_success.png"));
const payResult = ref(true); //支付结果
console.log('充值组件');
setTimeout(() => {
  // payResult.value = false;
  // resultVisible.value = true;
}, 2000);
watch(
  () => payResult.value,
  () => {
    if (payResult.value) {
      resultBtnTxt.value = "OK";
      resultInfo.value = "payResultSuccessInfo";
      resultImg.value = getImageUrlByNewURL("systemicon/notice_success.png");
    } else {
      resultBtnTxt.value = "TRY AGAIN";
      resultInfo.value = "payResultFailInfo";
      resultImg.value = getImageUrlByNewURL("systemicon/notice_error.png");
    }
  }
);
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0.03rem;
  // background: #1B2132;
  // background-color: yellow;
}

::-webkit-scrollbar-thumb {
  width: 0.03rem;
  border-radius: 0.3125rem;
  background: $active-color;
}
.active-card {
  @extend %clearBgi;
  // background-size: 100% 100%;
  background-image: url("@/assets/images/recharge/selected.png");
  .card {
    border: none !important;
  }
}

.promocode {
  // border: 2px solid red;
  height: 1.5rem;
  background-color: #161c2a;
  border-radius: 0.1rem;
  margin-bottom: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  .title{
    // border: 2px solid yellow;
    font-size: .2rem;
    font-weight: bold;
    color: #a3b2cf;
  }
  .col {
    width: 100%;
    // border: 2px solid red;
  }
  .input-container {
    margin-top: 0.19rem;
    height: 0.4rem;
    @extend %flex-all-center;
    .ant-input {
      width: 6.87rem;
      margin-right: 0.3rem;
    }
    .ant-btn {
      width: 1rem;
      height: 0.4rem;
      border-radius: 0.2rem;
      // background-color: #443439;
      color: #ffffff;
      font-size: 0.14rem;
      font-weight: bold;
      @extend %flex-all-center;
    }
    .disable-btn {
      background-color: #443439;
    }
    .able-btn {
      background-color: $active-color;
      &:hover {
        background-color: #eb336b;
      }
    }
  }
}

// 银联
.bank-container {
  // border: 2px solid red;
  .email,
  .amountleft,
  .amountright,
  .promocode {
    width: inherit;
    background-color: #161c2a;
  }

  .email {
    height: 1.5rem;
    padding: 0 0.5rem;
    .input-container {
      margin-top: 0.24rem;
      display: flex;
      .ant-input {
        width: 6.8rem;
      }
      .ant-btn {
        width: 1rem;
        height: 0.4rem;
        background-color: $active-color;
        border-radius: 0.2rem;
        font-size: 0.14rem;
        font-weight: bold;
      }
    }
  }
  .amount {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    height: 2.6rem;
    display: flex;
    justify-content: space-between;
    .amountleft,
    .amountright {
      width: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }

    .amountleft {
      padding: 0 0.5rem;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // justify-content: space-evenly;
      .acol {
        width: 100%;
      }

      .price-list {
        display: flex;

        justify-content: space-evenly;
        .ant-btn {
          width: 0.6rem;
          height: 0.3rem;
          border-radius: 0.15rem;
          background-color: #202737;
          @extend %flex-all-center;
        }
      }
    }
    .amountright {
      .pay-btn {
        .ant-btn {
          width: 1.7rem;
          height: 0.46rem;
          border-radius: 0.23rem;
          @extend %flex-all-center;
          background-color: $active-color;
          color: #ffffff;
          font-size: 0.2rem;
          font-weight: bold;
        }
      }
    }
  }
  .promocode {
    // height: 1.5rem;
  }
}

//虚拟货币
.virtual-container {
  height: 6.8rem;
  overflow-y: scroll;

  // .title {
  //   // border: 2px solid yellow;
  //   font-size: 0.2rem;
  //   font-weight: 400;
  //   color: #ffffff;
  // }

  .gift-list {
    background-color: #161c2a;
    padding: 0.4rem 0.3rem;
    margin-top: 0.3rem;
    margin-bottom: 0.4rem;
    display: grid;
    grid-template-columns: repeat(6, 1.2rem);
    row-gap: 0.2rem;
    column-gap: 0.2rem;
    .gift {
      width: 1.2rem;
      height: 0.6rem;
      border-radius: 0.3rem;
      cursor: pointer;
      background-color: #202737;
      @extend %flex-all-center;
      &:before {
        content: "$";
      }
      font-size: 0.2rem;
      font-weight: bold;
      color: #ffffff;
      &:hover {
        @extend .active-gift;
      }
    }
    .active-gift {
      border: 1px solid #1ffbce;
      background: #0e2722;
    }
  }

  .v-coins {
    margin-top: 0.3rem;
    display: grid;
    grid-template-columns: repeat(5, 1.74rem);
    grid-gap: 0.08rem;
    height: 5.6rem;
    .v-coin {
      cursor: pointer;
      width: 1.74rem;
      height: 1.8rem;
      border-radius: 0.1rem;
      background-color: #161c2a;
      @extend %flex-all-center;
      flex-direction: column;
      .v-img {
        width: 0.66rem;
        height: 0.42rem;
        margin-bottom: 0.3rem;
        img {
          width: 0.66rem;
        }
      }
      .v-name {
        font-size: 0.16rem;
        font-weight: 400;
        color: #dcdefc;
      }
      .v-alias {
        font-size: 0.14rem;
        font-weight: 400;
        color: #7d93c4;
      }
    }
  }

  .promocode {
    margin-top: 0.2rem;
    background-color: #161c2a;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border-radius: 0.1rem;
    // height: 1.5rem;
    // .title {
    //   font-weight: bold;
    //   font-size: 20px;
    //   color: #a3b2cf;
    // }
  }

  // 虚心币详情
  .coin-container {
    display: flex;
    padding: 0.33rem 0.4rem;
    .coin-left {
      margin-right: 0.4rem;
      width: 2.8rem;
      .back {
        cursor: pointer;
        font-size: .2rem;
        width: 1.3rem;
        height: 0.44rem;
        border-radius: 0.05rem;
        background-color: #272d40;
        @extend %flex-all-center;
      }
      .qrcode {
        width: 2rem;
        height: 2rem;
        border-radius: 0.1rem;
        background-color: #ffffff;
        margin-top: 0.95rem;
        @extend %flex-all-center;
        img {
          width: 1.92rem;
          height: 1.92rem;
        }
      }

      //虚拟充值提示
      .pay-info {
        font-size: 0.14rem;
        font-weight: bold;
        color: #a3b2cf;
        margin: 0.18rem 0 0.1rem 0;
        .error {
          &::before {
            content: "*";
          }
          &::after {
            content: ":";
          }
          color: $active-color;
        }
      }
      .require-info {
        width: 2rem;
        height: 0.46rem;
        border-radius: 0.23rem;
        color: #ffc600;
        font-size: 0.18rem;
        font-weight: bold;
        background-color: #1e2637;
        @extend %flex-all-center;
      }
    }
    .coin-right {
      .title {
        padding-left: 0.78rem;
        display: flex;
        align-items: center;
        img {
          width: 0.58rem;
          margin-right: 0.23rem;
        }
        span.coin-name {
          font-size: 0.32rem;
          font-weight: bold;
          color: #dcdefc;
        }
      }
      .content {
        margin-top: 0.5rem;
        .t1 {
          font-size: 0.21rem;
          font-weight: bold;
          color: #dcdefc;
        }
        .code-link {
          margin-top: 0.21rem;
          padding-left: 0.31rem;
          display: flex;
          align-items: center;
          background-color: #1e2637;
          border-radius: 0.1rem;
          height: 0.46rem;
          position: relative;
          font-size: 0.18rem;
          .copy-icon {
            right: 0.24rem;
            width: 0.14rem;
            position: absolute;
          }
          .gspan {
            color: #37e629;
            font-size: .18rem;
          }
        }
        .info {
          font-size: 0.14rem;
          font-weight: 400;
          color: #a3b2cf;
          display: flex;
          align-items: center;
          margin-top: 0.2rem;
          margin-bottom: 0.31rem;
          img {
            width: 0.16rem;
            height: 0.16rem;
            margin-right: 0.1rem;
          }
        }
        .title2 {
          font-weight: 400;
          font-size: .18rem;
          color: #dcdefc;
        }
        .message {
          margin-top: 0.32rem;
          .m1 {
            font-weight: 400;
            font-size: .18rem;
            color: #dcdefc;
            display: flex;
            align-items: center;
            img {
              width: .16rem;
              height: .16rem;
              margin-right: 0.1rem;
            }
          }
          .mt {
            font-weight: 400;
            font-size: .14rem;
            color: #a3b2cf;
            line-height: .18rem;
            margin-top: 0.27rem;
            .note {
              color: $active-color;
              font-size: bold;
              &::before {
                content: "*";
              }
              &::after {
                content: ":";
              }
            }
          }
        }
      }
    }
  }
}

//礼品卡
.gift-container {
  // border: 2px solid blue;
  height: 6.7rem;

  .gift-list {
    display: grid;
    grid-template-columns: repeat(5, 1.74rem);
    row-gap: 0.18rem;
    column-gap: 0.1rem;
    .gift {
      width: 1.74rem;
      height: 1.96rem;
      position: relative;
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
        transition: all 0.1s ease-in-out;
      }
      img {
        width: inherit;
      }
      .gift-price {
        &::before {
          content: "$";
        }

        padding: 0;
        line-height: 0.3rem;
        position: absolute;
        top: 0.4rem;
        right: 0.09rem;
        font-size: 0.4rem;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
  .gift-info {
    font-weight: 400;
    font-size: 0.16rem;
    color: #a3b2cf;
    margin: 0.3rem 0;
  }
}

//skrill
.skinsback-container {
  // border: 2px solid orange;
  height: 6.7rem;
  .block {
    // border: 2px solid green;
    height: 3rem;
    background-color: #161c2a;
    border-radius: 0.1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.2rem;
    .info {
      margin-bottom: 0.66rem;
      font-size: .24rem;
      font-weight: bold;
    }
    .btn {
      .ant-btn {
        width: 1.7rem;
        height: 0.46rem;
        background-color: $active-color;
        border-radius: 0.23rem;
        font-size: 0.2rem;
        font-weight: bold;
      }
    }
  }
}

.recharge-dialog {
  // border: 1px solid red;

  .recharge-modal {
    :deep(.ant-modal) {
      // border: 2px solid yellow;
      width: 17rem;
      height: 8.2rem;
      background-color: #0e121e;
      // background-color: #ed51af;
      .ant-modal-content {
        background-color: #0e121e;
        height: inherit;
      }
      .ant-modal-close {
        right: 0.4rem;
        top: 0.4rem;
        img {
          width: 0.16rem;
          // height: .16rem;
        }
      }
      .ant-modal-body {
        padding: 1rem 0.7rem 0 0.7rem;
        height: inherit;
        display: flex;
        // background-color: #0e121e;
        // border: 2px solid skyblue;
      }
      .recharge-container {
        display: flex;
        // border: 2px solid blue;
        .left,
        .right {
          // border: 2px solid green;
        }
        .left {
          width: 6.2rem;
          .title {
            @extend %flex-all-center;
            justify-self: flex-start;
            .picon {
              width: 0.36rem;
              height: 0.36rem;
              margin-right: 0.26rem;
            }
            span {
              font-weight: bold;
              font-size: 0.24rem;
              color: #ffffff;
            }
          }
          .line {
            height: 0.01rem;
            margin-top: 0.28rem;
            margin-bottom: 0.3rem;
            background-color: #272d40;
          }
          .list-container {
            .list-title {
              font-weight: 400;
              font-size: 0.18rem;
              color: #687088;
              margin-bottom: 0.2rem;
            }
            .list-cards {
              // border: 2px solid red;
              overflow-y: scroll;
              overflow-x: hidden;
              max-height: 4.1rem;
              display: grid;
              grid-template-columns: repeat(3, 1.98rem);
              grid-gap: 0.1rem;
              .card-item {
                // border: 2px solid yellow;
              }
              .card {
                height: 1.3rem;
                border: 1px solid #304b75;
                border-radius: 0.05rem;
                @extend %flex-all-center;
                .card-icon {
                  width: 2rem;
                  height: 1.3rem;
                }
                &:hover {
                  cursor: pointer;
                  border: none;
                  @extend .active-card;
                }
              }
            }
          }
        }
        .right {
          width: 9.1rem;
          height: 6rem;
          margin-left: 0.3rem;
        }
      }
    }
  }
}

//充值结果弹框
.recharge-result-modal {
  :deep(.ant-modal) {
    width: 4.96rem !important;
    height: 2.9rem;
    top: 20%;
    background-color: #0e121e;
    .ant-modal-close-x {
      position: absolute;
      top: 0.14rem;
      right: 0.1rem;
      // img {
      //   width: 0.4rem;
      //   height: 0.4rem;
      // }
    }
    .ant-modal-body {
      height: 2.9rem;
      @extend %flex-all-center;
      flex-direction: column;
      .tips {
        color: #ffffff;
        font-size: 0.24rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
      .content {
        width: 100%;
        @extend %flex-all-center;
        flex-direction: column;
        .info {
          @extend %flex-all-center;
          font-size: 0.17rem;
          font-weight: 400;
          color: #a3b2cf;
          .infos {
            width: max-content;
            max-width: 3.1rem;
          }
          img {
            width: 0.4rem;
            margin-right: 0.1rem;
          }
        }
        .result-btn {
          &:hover {
            background-color: #eb336b;
          }
          margin-top: 0.31rem;
          width: 1.7rem;
          height: 0.46rem;
          border-radius: 0.23rem;
          background-color: $active-color;
          font-size: 0.2rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
