import { computed } from "vue";

//删除StatTrak™
const delST = computed(() => {
  return (val) => {
    if (val.includes("StatTrak™")) {
      // return val.replace('StatTrak™','')
      //道具名包含StatTrak™ 则返回ST缩写
      return ["ST", val.replace("StatTrak™", "")];
    }
    return val;
  };
});
export default function (val) {
  return delST.value(val);
}

