<template>
  <div class="wrapper">
    <div class="navbar-top">
      <div class="nav-l">
        <div class="nav-top-item online">
          <img src="@/assets/images/systemicon/online.png" alt="" />
          <span class="fwb fz16">{{ sitePublic.online_number }}</span>
        </div>
        <div class="nav-top-item fz14 fw4 faq" @click="toPolicy">{{ $t("faq") }}</div>
        <div class="nav-top-item fair fz14 fwb" @click="checkRoll">
          {{ $t("fairnessVerification") }}
          <img src="@/assets/images/systemicon/verify.png" alt="" />
        </div>
        <div class="nav-top-item"></div>
      </div>
      <div class="nav-r" ref="navrRef">
        <!-- <div class="langrage" @click="click"></div> -->
        <div class="social">
          <a href="https://t.me/skinroll_net" target="_blank" rel="noopener noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="58"
              viewBox="0 0 70 58"
              id="telegram-svg"
            >
              <path
                id="telegram"
                data-name="telegram"
                class="cls-1"
                d="M5.055,24.838q28.06-12.208,37.413-16.1C60.293,1.339,63.993.053,66.409,0.01a4.227,4.227,0,0,1,2.486.746,2.756,2.756,0,0,1,.912,1.735,11.326,11.326,0,0,1,.1,2.522c-0.962,10.133-5.143,34.723-7.268,46.073-0.892,4.8-2.667,6.413-4.381,6.57C54.529,58,51.7,55.2,48.093,52.835c-5.644-3.7-8.832-6-14.316-9.607-6.336-4.169-2.226-6.461,1.383-10.206,0.942-.98,17.363-15.895,17.674-17.247a1.289,1.289,0,0,0-.3-1.133,1.468,1.468,0,0,0-1.323-.129q-0.857.192-27.068,17.866-3.834,2.634-6.957,2.567c-2.286-.049-6.7-1.294-9.975-2.357C3.2,31.285,0,30.595.283,28.379q0.211-1.73,4.772-3.54h0Z"
              />
            </svg>
          </a>

          <a
            href="https://www.facebook.com/profile.php?id=61564824058998"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="74"
              height="154"
              viewBox="0 0 74 154"
              id="facebook-svg"
            >
              <path
                id="facebook"
                data-name="facebook"
                class="cls-1"
                d="M72.7,51.29H46.173V42.5c0-13.156,5.13-18.2,18.443-18.2,4.123,0,7.468.1,9.384,0.293V2.021A89.4,89.4,0,0,0,56.369,0C29.255,0,16.754,12.832,16.754,40.544V51.257H0V76.136H16.754V154H46.2V76.168H68.156Z"
              />
            </svg>
          </a>
        </div>
        <cDropDown :getPopupContainer="()=>$refs.navrRef" dropdownPlacement="bottomLeft">
          <template #title>
            <div class="langrage-inner">
              <img class="currentimg" :src="currentLangImg" alt="" />
              <!-- &#9660; -->
              <div class="jb"></div>
            </div>
          </template>
          <template #cmenus>
            <a-menu>
              <a-menu-item v-for="lanitem in langList" :key="lanitem.key">
                <div class="lang-item" @click="selected(lanitem)">
                  <img :src="lanitem.nationalFlag" alt="" />
                  <span>{{ lanitem.lang }}</span>
                </div>
              </a-menu-item>
            </a-menu>
          </template>
        </cDropDown>
        <template v-if="u.isToken">
          <div class="siteinfo info" @click="showMsg" v-if="0">
            <img src="@/assets/images/systemicon/siteinfo.png" alt="站内信" />
            <div class="noti-dot"></div>
          </div>
          <div class="siteinfo tui" @click="logout">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="20px"
              height="18px"
              viewBox="0 0 20 18"
              id="quit-svg"
            >
              <path
              class="cls-1"
                d="M19.723,9.661 C19.720,9.664 19.715,9.665 19.712,9.668 L16.051,13.285 C15.682,13.649 15.084,13.649 14.715,13.285 C14.346,12.920 14.346,12.330 14.715,11.966 L16.771,9.935 L6.497,9.935 C5.981,9.935 5.562,9.516 5.562,9.000 C5.562,8.484 5.981,8.065 6.497,8.065 L16.771,8.065 L14.715,6.034 C14.346,5.670 14.346,5.080 14.715,4.715 C15.084,4.351 15.682,4.351 16.051,4.715 L19.712,8.332 C19.715,8.335 19.720,8.336 19.723,8.339 C19.908,8.521 19.999,8.761 19.999,9.000 C19.999,9.239 19.908,9.479 19.723,9.661 ZM12.544,4.675 C12.022,4.675 11.598,4.257 11.598,3.740 L11.598,2.870 C11.598,2.318 11.150,1.870 10.598,1.870 L2.893,1.870 C2.341,1.870 1.893,2.318 1.893,2.870 L1.893,15.130 C1.893,15.682 2.341,16.130 2.893,16.130 L10.598,16.130 C11.150,16.130 11.598,15.682 11.598,15.130 L11.598,14.260 C11.598,13.743 12.022,13.325 12.544,13.325 C13.067,13.325 13.491,13.743 13.491,14.260 L13.491,16.000 C13.491,17.105 12.596,18.000 11.491,18.000 L2.000,18.000 C0.895,18.000 0.000,17.105 0.000,16.000 L0.000,2.000 C0.000,0.895 0.895,-0.000 2.000,-0.000 L11.491,-0.000 C12.596,-0.000 13.491,0.895 13.491,2.000 L13.491,3.740 C13.491,4.257 13.067,4.675 12.544,4.675 Z"
              />
            </svg>
          </div>
        </template>
      </div>
    </div>
    <div class="navbar">
      <div class="logo">
        <img src="@/assets/images/systemicon/logo.svg" alt="" />
      </div>
      <div class="nav-list">
        <router-link
          :class="{ activeNavItem: u.navi == navitem.name }"
          class="nav-item link_a"
          :style="{
            '--bgi':
              u.navi == navitem.name
                ? 'url(' + navitem.selectedImagePath + ')'
                : 'url(' + navitem.imagePath + ')',
          }"
          :to="navitem.path"
          v-for="(navitem, index) in navList"
          :key="navitem.path"
          @click="clickNav(navitem, index)"
          @mouseenter="u.currentNav = navitem.name"
          @mouseleave="u.currentNav = u.currentNav"
          >{{ $t(navitem.navTitle) }}
          <div :class="{ activeLine: u.navi == navitem.name }"></div>
        </router-link>
      </div>
      <div ref="accountRef" class="account" v-if="u.isToken">
        <a-button class="cs-cus-btn btn fwb" @click="doRefill">
          {{ $t("refill") }}
          <template #icon>
            <img src="@/assets/images/systemicon/pay.png" alt="" />
          </template>
        </a-button>
        <div class="user" @click="toMine">
          <div>
            <div class="u-name fz14 fw4">{{ userInfo.name }}</div>
            <!-- <div class="balance fz18 fwb">{{ parseFloat(userInfo.bean).toFixed(2) }}</div> -->
            <div class="balance fz18 fwb">{{ parseFloat(bean.num).toFixed(2) }}</div>
            <!-- 减去 -->
            <div class="updateAccount">
              <span class="balance1">{{ updateStr }}</span>
            </div>
          </div>
          <div
            class="avatar-wp"
            :style="{ 'border-color': useDynamicLevelColor(userInfo.level || 1) }"
          >
            <img :src="$dimg(userInfo.avatar)" alt="" />
            <div
              class="level"
              :style="{ 'background-color': useDynamicLevelColor(userInfo.level || 1) }"
            >
              {{ userInfo.level || 1 }}
            </div>
          </div>
        </div>
      </div>
      <a-button v-else class="login" @click="login">
        {{ $t("loginLarge") }}
        <template #icon>
          <img src="@/assets/images/index/login_steam.png" alt="" />
        </template>
      </a-button>
    </div>
    <div class="user-card" v-show="isShowUserCard">
      <div class="account" v-if="u.isToken">
        <a-button class="cs-cus-btn btn fwb" @click="doRefill">
          {{ $t("refill") }}
          <template #icon>
            <img src="@/assets/images/systemicon/pay.png" alt="" />
          </template>
        </a-button>
        <div class="user" @click="toMine">
          <div>
            <div class="u-name fz14 fw4">{{ userInfo.name }}</div>
            <!-- <div class="balance fz18 fwb">{{ parseFloat(userInfo.bean).toFixed(2) }}</div> -->
            <div class="balance fz18 fwb">{{ parseFloat(bean.num).toFixed(2) }}</div>
            <!-- 减去 -->
            <div class="updateAccount">
              <span class="balance1">{{ updateStr }}</span>
            </div>
          </div>
          <div
            class="avatar-wp"
            :style="{ 'border-color': useDynamicLevelColor(userInfo.level || 1) }"
          >
            <img :src="$dimg(userInfo.avatar)" alt="" />
            <div
              class="level"
              :style="{ 'background-color': useDynamicLevelColor(userInfo.level || 1) }"
            >
              {{ userInfo.level || 1 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import eventEmitter from "@/utils/emitter.js";
import i18n from "@/i18n";
const { t: $t, locale } = i18n.global;
import { useLangStore } from "@/store/module/i18n.js";
import {
  ref,
  getCurrentInstance,
  nextTick,
  h,
  watch,
  onMounted,
  reactive,
  computed,
  watchEffect,
  onBeforeUnmount,
} from "vue";
import { useDynamicLevelColor } from "@/hook/useLevelColor.js";
import isBalanceEnough from "@/utils/isBalanceEnough.js";
import getAccountBalance from "@/utils/getAccountBalance.js";
import nationImgModal from "@/utils/dynamicImg.js";
import cDropDown from "@/components/common/dropdown/index.vue";
import $store from "@/store";
import { storeToRefs } from "pinia";
// import { loginModal } from "@/components/common/login/fnlogin.js";
import { loginModal } from "@/components/common/login";

import { useRouter } from "vue-router";
// 语言包
import langPkg from "@/i18n/locales";
import ColumnGroup from "ant-design-vue/lib/vc-table/sugar/ColumnGroup";
import { notification } from "ant-design-vue";
import { SmileOutlined } from "@ant-design/icons-vue";
import { getImageUrlByNewURL } from "@/utils/getImageUrl.js";
import { gsap } from "gsap";
import computeNumber from "@/utils/calcTotal.js";

//userStore
const u = $store("user");
const siteStore = $store("siteInfo");
const { sitePublic } = storeToRefs(siteStore);
const { userInfo } = storeToRefs(u);
const bean = reactive({
  // num:userInfo.bean
  num: u.userInfo.bean || 0,
});
watch(
  () => u.userInfo.bean,
  (newval, oldval) => {
    gsap.to(bean, {
      duration: 0.5,
      num: newval,
      onComplete() {
        // console.log("ddd;", u.userInfo.bean);
      },
    });
  }
);

const router = useRouter();

//View the dice roll
function checkRoll(roll_id) {
  router.push(`/seed`);
}

// const { t:$t, locale } = i18n.global;
const lanStore = useLangStore();
const locLang = ref(lanStore.language);
//js中获取当前语言
// console.log('lanL:',i18n.global.t('login'));
// console.log('$t lanL:',$t('login'));
// console.log('lanL:',i18n.global.t('login'));
// console.log('lanL:',i18n.global.t('loginobj'));
const { proxy } = getCurrentInstance();

const updateCount = ref({ type: "increment", val: 0, paytype: false });
const updateStr = computed(() => {
  let type = "";
  switch (updateCount.value.type) {
    case "increment":
      type = "+";
      break;
    case "decrement":
      type = "-";
      break;
  }
  return type + "$ " + updateCount.value.val;
});
proxy.$EventBus.on("upd", (params) => {
  //isBalanceEnough  返回payType的值金币或钻石;余额不足则返回false--在false处处理余额不足问题
  const paytype = params.paytype ? params.paytype : isBalanceEnough(u, proxy, params.val);
  if (!paytype) {
    //noEnough 余额不足
    u.noEnough = true;
    // proxy.$EventBus.emit("noEnough", { type: "noEnough" });
    doRefill();
    return console.log("余额不足,前往充值页面");
  }
  console.log("paytype:", paytype);

  updateCount.value = params;
  //短时间内多次执行扣费动画 如何优化
  gsap.from(".updateAccount", {
    y: 20,
    duration: 1.2,
    opacity: 1,
    delay: 0.1,
    onComplete() {
      // console.log("扣费结束");
      gsap.set(".updateAccount", { y: 0, opacity: 0 });
    },
  });

  getAccountBalance(paytype, u, params.val);
});

//测试并发扣费
// const isClick = ref(false);
// function testDeduction() {
//   console.log("扣除");
//   if (isClick.value) return;
//   isClick.value = true;
//   proxy.$EventBus.emit("upd", { type: "decrement", val: 100 });
//   gsap.delayedCall(1, () => {
//     isClick.value = false;
//   });
// }

const noticeCount = ref(0);
//测试全局提示
function showMsg() {
  // console.log("test all tips");
  proxy.$notice("s", {
    desc:
      "This is the content of the notification.This is the content of the notification.This is the content of the notification.This is the content of the notification.",
  });
  // notification.error({
  //   placement:'bottomLeft',
  //   class: "cus-err",
  //   key:noticeCount.value,
  //   message: "title"+noticeCount.value,
  //   description: "This is the content of the notification. ",
  //   onClose:()=>{
  //     console.log('notice is close');
  //   },
  //   icon: () =>
  //     h("img", {
  //       src: getImageUrlByNewURL("systemicon/notice_error.png"),
  //       style: "color: #108ee9",
  //       class: "notice-icon",
  //     }),
  // });
  // noticeCount.value++;
}
// watch(
//   () => noticeCount.value,
//    () => {
//     nextTick(()=>{
//       console.log('count;',noticeCount.value);
//       const cusErrLen = document.getElementsByClassName("cus-err");

//       //watch dom.length
//       console.log("cuserrlen;", cusErrLen);
//       console.log("len:", cusErrLen.length);
//       console.log('html;',cusErrLen[cusErrLen.length-1]);

//     })
//   }
// );

function toPolicy() {
  // console.log("跳转常见问题解答");
  // return
  const val = { id: 2 };
  router.push(`/policy/${val.id}`);
}

//可使用的语言
const langList = ref({});
for (const key in langPkg) langList.value[key] = key;
//语言相关配置
const langArr = [
  {
    key: "en-gb",
    lang: "English",
    // nationalFlag: proxy.$getImageUrlByNewURL("systemicon/nationalflag/en-gb.svg"),
  },
  {
    key: "zh-cn",
    lang: "简体中文",
    // nationalFlag: proxy.$getImageUrlByNewURL("systemicon/nationalflag/zh-cn.png"),
  },
  {
    key: "zh-tw",
    lang: "繁体中文",
    // nationalFlag: proxy.$getImageUrlByNewURL("systemicon/nationalflag/zh-cn.png"),
  },
  {
    key: "pl",
    lang: "POLISH",
    // nationalFlag: proxy.$getImageUrlByNewURL("systemicon/nationalflag/zh-cn.png"),
  },
  {
    key: "ru",
    lang: "RUSSIA",
    // nationalFlag: proxy.$getImageUrlByNewURL("systemicon/nationalflag/zh-cn.png"),
  },
  {
    key: "fr",
    lang: "FRENCH",
    // nationalFlag: proxy.$getImageUrlByNewURL("systemicon/nationalflag/zh-cn.png"),
  },
  {
    key: "jp",
    lang: "JAPANESE ",
    // nationalFlag: proxy.$getImageUrlByNewURL("systemicon/nationalflag/zh-cn.png"),
  },
  {
    key: "de",
    lang: "DEUTSCH",
    // nationalFlag: proxy.$getImageUrlByNewURL("systemicon/nationalflag/zh-cn.png"),
  },
  {
    key: "vn",
    lang: "VIETNAMESE  ",
    // nationalFlag: proxy.$getImageUrlByNewURL("systemicon/nationalflag/zh-cn.png"),
  },
  {
    key: "kr",
    lang: "KOREAN ",
    // nationalFlag: proxy.$getImageUrlByNewURL("systemicon/nationalflag/zh-cn.png"),
  },
];
for (const obj of langArr) {
  // console.log("key:", obj.key);
  for (const key in nationImgModal) {
    if (key == obj.key) {
      obj.nationalFlag = nationImgModal[key];
    }
  }
}
//根据系统语言包,生成最终需要的语言相关数据,供给用户选择要使用的语言
langArr.forEach((e) => {
  if (langList.value[e.key] == e.key) {
    langList.value[e.key] = e;
  }
});
const currentLangImg = ref();
// console.log("langlist after:", langList.value);

for (const key in langList.value) {
  // console.log("key:", key);
  if (lanStore.language == key) {
    currentLangImg.value = langList.value[key].nationalFlag;
    break;
  } else {
  }
}
// currentLangImg.value = langList.value.filters((v) => {
//   if (lanStore.language == v.key) {
//     return v.nationalFlag;
//   } else {
//     return langArr.filter((v) => {
//       if (v.key == "en-gb") {
//         return v.nationalFlag;
//       }
//     });
//   }
// });

// console.log("dangqian:", lanStore.language);
//切换语言

const selected = (lanitem) => {
  console.log("cL:", lanitem);
  currentLangImg.value = lanitem.nationalFlag;
  lanStore.changeLang(lanitem.key);
};

//监听底部语言切换,切换导航栏语言图标
eventEmitter.on("lang:change", (params) => {
  // console.log("lang:change:", params);
  currentLangImg.value = langList.value[params].nationalFlag;
});

// proxy.$EventBus.on("langChange", (params) => {
//   console.log("langChange:", params);
//   return
//   currentLangImg.value = langList.value[params].nationalFlag;
//   lanStore.changeLang(params);
// });

//切换语言
const click = () => {
  // console.log("click");
  if (locLang.value == "en-gb") {
    locLang.value = "zh-cn";
  } else {
    locLang.value = "en-gb";
  }
  lanStore.changeLang(locLang.value);
};

//导航栏数据 $myt
const navList = ref([
  {
    navTitle: "navBox",
    imagePath: proxy.$getImageUrlByNewURL("systemicon/navicon/box.png"),
    selectedImagePath: proxy.$getImageUrlByNewURL("systemicon/navicon/boxSelected.png"),
    path: "/box",
    name: "box",
    checked: true,
  },
  {
    navTitle: "navBattle",
    imagePath: proxy.$getImageUrlByNewURL("systemicon/navicon/battle.png"),
    selectedImagePath: proxy.$getImageUrlByNewURL(
      "systemicon/navicon/battleSelected.png"
    ),
    path: "/battle",
    name: "battle",
    checked: false,
  },
  // {
  //   navTitle: "navRoll",
  //   imagePath: proxy.$getImageUrlByNewURL("systemicon/navicon/roll.png"),
  //   selectedImagePath: proxy.$getImageUrlByNewURL("systemicon/navicon/rollSelected.png"),
  //   path: "/roll",
  //   name: "roll",
  //   checked: false,
  // },
  {
    navTitle: "navUpgrade",
    imagePath: proxy.$getImageUrlByNewURL("systemicon/navicon/contract.png"),
    selectedImagePath: proxy.$getImageUrlByNewURL(
      "systemicon/navicon/contractSelected.png"
    ),
    path: "/upgrade",
    name: "upgrade",
    needLogin: true,
    checked: false,
  },

  {
    navTitle: "navExchange",
    imagePath: proxy.$getImageUrlByNewURL("systemicon/navicon/exchange.png"),
    selectedImagePath: proxy.$getImageUrlByNewURL(
      "systemicon/navicon/exchangeSelected.png"
    ),
    path: "/exchange",
    name: "exchange",
    needLogin: true,
    checked: false,
  },
  {
    navTitle: "navGift",
    imagePath: proxy.$getImageUrlByNewURL("systemicon/navicon/dailycase.png"),
    selectedImagePath: proxy.$getImageUrlByNewURL(
      "systemicon/navicon/dailycaseSelected.png"
    ),
    path: "/daily",
    name: "daily",
    checked: false,
  },
  {
    navTitle: "navMission",
    imagePath: proxy.$getImageUrlByNewURL("systemicon/navicon/mission.png"),
    selectedImagePath: proxy.$getImageUrlByNewURL(
      "systemicon/navicon/missionSelected.png"
    ),
    path: "/mission",
    name: "mission",
    checked: false,
  },
]);

watch(
  () => router.currentRoute.value.path,
  (toPath) => {
    //要执行的方法
    // console.log("router.currentRoute", router.currentRoute.value.name);
    // console.log("toPath:", toPath);
    const nl = navList.value.some((v) => {
      return v.path == toPath;
    });
    if (!nl) {
      //路由不包含导航栏的路径,直接返回
      u.navi = 0;
      return;
    }

    u.navi = router.currentRoute.value.name;
    // console.log("navi:", u.navi);
  },
  { immediate: true, deep: true }
);

//选中导航栏
const clickNav = (navitem, index) => {
  if (u.navi == index) return;
  if (navitem.needLogin && !u.isLogin) {
    return;
  }
  u.navi = navitem.name;
  u.currentNav = navitem.name;
  if (navitem.checked) return;
  // console.log("current navlist:", navitem.path);
  navitem.checked = true;
  navList.value.map((v) => {
    if (v.path != navitem.path) {
      v.checked = false;
    }
  });
};

//悬浮导航栏
function mouseenterNav(navitem, index) {
  // console.log("悬浮导航栏", navitem, index);
}

//充值
function doRefill() {
  // console.log("个人中心充值");
  eventEmitter.emit("api:no_enough");
}

//前往用户中心
function toMine() {
  router.push("/user");
}

//显示登录弹框
function login() {
  loginModal(
    { visible: true, title: $t("loginLarge"), contentType: "def" },
    (callnack) => {
      // console.log('未登录 弹登录框');
      callnack();
    }
  );
}

//退出登录
function logout() {
  u.logout();
  router.push("/");
}

const isShowUserCard = ref(false);
const accountRef = ref(null);
watch(
  () => isShowUserCard.value,
  () => {
    if (isShowUserCard.value) {
      console.log("---");
      // gsap.fromTo(
      //   ".user-card",
      //   {
      //     top: "-0.8rem",
      //   },
      //   {
      //     top: "0.2rem",
      //     duration: 0.3,
      //     ease: "power3.inOut",
      //   }
      // );
      gsap.from(".user-card", {
        top: "-0.8rem",
        duration: 0.3,
      });
    }
  }
);
onMounted(() => {
  document.addEventListener("scroll", (e) => {
    // 获取当前滚动位置
    const scrollTop = window.scrollY;
    //获取dom边界信息
    // console.log('oo;',accountRef.value.offsetTop);
    if(!accountRef.value?.offsetTop){
      return;
    }
    const initialOffsetTop = accountRef?.value?.offsetTop + 20;
    const userCard = document.querySelector(".user-card");
    console.log("scrollTop:", scrollTop, "initialOffsetTop:", initialOffsetTop);
    //判断是否在可视区域
    if (scrollTop > initialOffsetTop) {
      isShowUserCard.value = true;
      console.log("显示用户卡片");
    } else {
      isShowUserCard.value = false;
      userCard && (userCard.style.top = ".2rem");
    }
  });
});
onBeforeUnmount(() => {
  // document.removeEventListener("scroll");
});
</script>
<style lang="scss" scoped>
@import "./index.scss";
.cls-1 {
  fill: #647284;
  fill-rule: evenodd;
}

//自定义组件样式
:global(.ant-dropdown-menu) {
  border: 1px solid #262d40;

  width: 2.2rem;
  // height: 2.2rem;
  // max-height: 270px;
  overflow-x: hidden;
  overflow-y: scroll;
  font-size: 0.14rem;
  background-color: $app-bg-color !important;
  // @extend .fw4;
  // @extend .fz14;
}
:global(.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected) {
  background-color: $app-bg-color !important;
}
.ant-dropdown-menu-item {
  color: #6c748a !important;
  @extend .fw4;
  @extend .fz14;
  .ant-dropdown-menu-title-content {
    border: none;
  }
  .lang-item {
    display: flex;
    align-items: center;
    margin-left: 0.1rem;
    margin-right: 0.2rem;
    padding: 0;
    // width: 190px;
    // height: 50px;
    width: 1.9rem;
    height: 0.5rem;
    border-bottom: 1px solid #262d40;
    img {
      width: 0.27rem;
      height: 0.18rem;
      margin-right: 0.21rem;
    }
    span {
      color: #6c748a;
      @extend .fw4;
      @extend .fz14;
    }
  }
}
.ant-dropdown-menu-item:last-child {
  // border: 2px solid yellow;
  .lang-item {
    border-bottom: 2px solid transparent;
  }
}

//u全局提示
:global(.ant-notification) {
  // border: 2px solid red;
}
:global(.ant-notification-notice) {
  overflow: visible;
  min-height: 100px;
  border-radius: 5px;
  // width: max-content;
  // border: 2px solid rgb(103, 238, 69);
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  padding: 16px 24px 16px 0;

  //success
  // background: linear-gradient(90deg, #2f1117 0%, #1b2031 100%);
  //fail
  // background: linear-gradient(90deg, #112F15 0%, #1B2031 100%);
}
:global(.ant-notification-notice-message) {
  //QUES  why 在内部不能获取样式,必须提出到外部global
  font-size: 16px;
  font-weight: bold;
  //success
  // color: #3fd03b;
  //fail
  // color: #d03b68;
}
//自定义icon
:global(.ant-notification-notice-icon) {
  left: 0;
  top: 50%;
  transform: translate(-60%, -50%);
}
//关闭icon
:global(.ant-notification-notice-close) {
  color: #ffffff;
  :hover {
    color: #ffffff !important;
  }
}
</style>
