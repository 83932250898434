import eventEmitter from "@/utils/emitter.js";
const TokenKey = 'token'
const localLang = 'localLang'
export function getToken() {
  return localStorage.getItem(TokenKey)
}
export function setToken(token) {
  return localStorage.setItem(TokenKey,token)
}
export function removeToken() {
   localStorage.removeItem(TokenKey);
  //  eventEmitter.emit("api:redirect");
   return;
}
export function getLang() {
  return localStorage.getItem(localLang)
}