<template>
  <div class="footer-container">
      <div class="comm">
        <!-- <div class="comm-i">For Kinguin</div>
        <div class="comm-i">SKINSBACK</div>
        <div class="comm-i">WhitePay</div> -->
        <div class="inner">
        <div class="comm-i">
          <img src="@/assets/images/systemicon/logo_kinguin.svg" alt="">
        </div>
        <div class="comm-i">
          <img src="@/assets/images/systemicon/logo_skinsback.svg" alt="">
        </div>
        <div class="comm-i">
          <img src="@/assets/images/systemicon/logo_whitepay.svg" alt="">
        </div>
        <div class="comm-i">
          <img src="@/assets/images/systemicon/logo_nowpayments.svg" alt="">
        </div>

        </div>
      </div>
    <div class="footer">
      <div class="block-l">
        <div class="mine">
          <div class="logo">
            <!-- <img src="@/assets/images/systemicon/logos.svg" alt=""> -->
          </div>
          <div class="about" v-if="10">
            <div>Skinroll © 2024</div>
            <div>
              {{ $t("footInfo1") }}
            </div>
            <div>
              {{ $t("footComp") }}
            </div>
          </div>
        </div>
        <!-- 协议 联系我们等 -->
        <div class="help-dev">
          <span  @click="toPolicy(p)" v-for="p in policyList" :key="p.id">{{
            $t(p.name)
          }}</span>
         
        </div>

        <!-- 轮播图标记 -->
        <!-- <div class="tag top3tag" >
          <img src="@/assets/images/index/top3tag.png" alt="">
          <div class="taginfo">WOW DROP</div>
        </div>
        <div class="tag swipertag">
          <img src="@/assets/images/index/swipertag.png" alt="">
          <div class="taginfo">LIVE DORP</div>
        </div> -->
        
       
      </div>
      <div class="block-r">
        <div class="site-info">
          <div class="info" v-for="item in siteInfoG" :key="item.id">
            <siteInfo v-bind="item"></siteInfo>
          </div>
        </div>
        <!-- 国旗 语言 -->

        <!-- 底部国旗语言栏 -->
        <div class="langrage-block" v-if="0">
          <!-- <a
            @click="taggleLan(key)"
            :href="'/' + key"
            class=""
            data-v-5b69547f=""
            v-for="(nationItem, key) in nationImgModal"
            :key="key"
          >
            <img :src="nationItem" alt="en 语言" />
          </a> -->
          <div
            class="lang"
            @click="taggleLan(key)"
            v-for="(nationItem, key) in nationImgModal"
            :key="key"
          >
            <img :src="nationItem" alt="en 语言" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import siteInfo from "@/components/common/siteInfo.vue";
import nationImgModal from "@/utils/dynamicImg.js";
import { ref, getCurrentInstance, toRefs, computed } from "vue";
import { useLangStore } from "@/store/module/i18n.js";
import $store from "@/store";
import i18n from "@/i18n";
import { useRouter } from "vue-router";
const router = useRouter();
const { t: $t, locale } = i18n.global;
const { proxy } = getCurrentInstance();
const siteStore = $store("siteInfo");
const lanStore = useLangStore();
// 全局站点公开信息 $myt
const siteInfoG = ref([
  {
    id: 1,
    message: "user_number",
    type: "user",
    icon: proxy.$getImageUrlByNewURL("systemicon/userg.png"),
    total: computed(() => siteStore.sitePublic.user_number),
  },
  {
    id: 2,
    message: "battles_number",
    icon: proxy.$getImageUrlByNewURL("systemicon/battleg.png"),
    type: "battle",
    total: computed(() => siteStore.sitePublic.battles_number),
  },
  {
    id: 3,
    message: "upgrades_number",
    icon: proxy.$getImageUrlByNewURL("systemicon/updateg.png"),
    type: "upgrade",
    total: computed(() => siteStore.sitePublic.upgrades_number),
  },
  {
    id: 4,
    message: "open_box_number",
    icon: proxy.$getImageUrlByNewURL("systemicon/keyg.png"),
    type: "box",
    total: computed(() => siteStore.sitePublic.open_box_number),
  },
]);



//点击图片切换对应语言
const taggleLan = (lanfield) => {
  console.log("lan:", lanfield);
  lanStore.changeLang(lanfield);
};

function storeDataInIndexedDB2(data) {
  const request = indexedDB.open("PolicyDB", 1);

  request.onupgradeneeded = function (event) {
    const db = event.target.result;
    if (!db.objectStoreNames.contains("policies")) {
      db.createObjectStore("policies", { keyPath: "id" });
    }
  };

  request.onsuccess = function (event) {
    const db = event.target.result;
    const transaction = db.transaction("policies", "readwrite");
    const store = transaction.objectStore("policies");

    const policyData = {
      id: data.id, // 假设 data 对象中有 id 字段
      content: JSON.stringify(data),
    };

    const addRequest = store.add(policyData);

    addRequest.onsuccess = function () {
      console.log("数据已成功存储到 IndexedDB");
    };

    addRequest.onerror = function (event) {
      console.log("存储数据时出错:", event.target.error);
    };
  };

  request.onerror = function (event) {
    console.log("打开数据库时出错:", event.target.error);
  };
}
function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("PolicyDB", 1);

    request.onupgradeneeded = function (event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("policies")) {
        db.createObjectStore("policies", { keyPath: "id" });
      }
    };

    request.onsuccess = function (event) {
      resolve(event.target.result);
    };

    request.onerror = function (event) {
      reject(event.target.error);
    };
  });
}

async function storeDataInIndexedDB(val) {
  try {
    const db = await openDatabase();
    const transaction = db.transaction("policies", "readwrite");
    const store = transaction.objectStore("policies");

    const policyData = {
      id: val.id, // 假设 data 对象中有 id 字段
      content: JSON.stringify(val.data),
    };

    const addRequest = store.put(policyData);

    addRequest.onsuccess = function () {
      console.log("数据已成功存储到 IndexedDB");
    };

    addRequest.onerror = function (event) {
      console.log("存储数据时出错:", event.target.error);
    };
  } catch (error) {
    console.log("数据库初始化或操作出错:", error);
  }
}

function toPolicy(val) {
  console.log("政策 ", val);

  // router.push({
  //   name: "policy",
  //   state: { data: JSON.stringify(val.data) },
  // });
  // if (localStorage.getItem("policy")) {
  //   localStorage.removeItem("policy");
  // }
  // if (val.id == 4) {
  //   // 将数据存储到 IndexedDB
  //   storeDataInIndexedDB(val);
  // } else {
  //   localStorage.setItem("policy", JSON.stringify(val.data));
  // }
  // 路由跳转并传递 history.state 数据
  router.push(`/policy/${val.id}`);
}

const policyList = ref([
  {
    id: 1,
    name: "cookie",
    data: {
      h1: "cookie",
      list: [
        {
          h2: "policyCookieTitle1",
          list: ["pct1a", "pct1b"],
        },
        {
          h2: "policyCookieTitle2",
          list: ["pct2a", "pct2b"],
        },
        {
          h2: "policyCookieTitle3",
          list: ["pct3a"],
        },
        {
          h2: "policyCookieTitle4",
          list: ["pct4a"],
        },
        {
          h2: "policyCookieTitle5",
          list: ["pct5a"],
        },
        {
          h2: "policyCookieTitle6",
          list: ["pct6a"],
        },
      ],
    },
  },
  {
    id: 2,
    name: "faqAnswer",
    data: {
      h1: "faqAnswer",
      list: [
        {
          h2: "policyFaqAnswerTitle1",
          list: ["pfat1a"],
        },
        {
          h2: "policyFaqAnswerTitle2",
          list: ["pfat1a"],
        },
        {
          h2: "policyFaqAnswerTitle3",
          list: ["pfat1a"],
        },
        {
          h2: "policyFaqAnswerTitle4",
          list: ["pfat1a"],
        },
        {
          h2: "policyFaqAnswerTitle5",
          list: ["pfat1a"],
        },
        {
          h2: "policyFaqAnswerTitle6",
          list: ["pfat1a"],
        },
        {
          h2: "policyFaqAnswerTitle7",
          list: ["pfat1a"],
        },
        {
          h2: "policyFaqAnswerTitle8",
          list: ["pfat1a"],
        },
      ],
    },
  },
  {
    id: 3,
    name: "contacts",
    data: {
      h1: "contacts",
      list: [
        {
          h2: "policyContactsTitle1",
          list: ["pctus1a"],
        },
        {
          h2: "policyContactsTitle2",
          list: ["pctus2a", "pctus2b", "pctus2c", "pctus2d", "pctus2e"],
        },
        {
          h2: "policyContactsTitle3",
          list: ["pctus3a"],
        },
      ],
    },
  },
  {
    id: 4,
    name: "serviceInfo",
    data: {
      h1: "serviceInfo",
      list: [
        {
          h2: "policyServiceTitle1",
          list: [
            "pst1a",
            "pst1b",
            "pst1c",
            "pst1d",
            // "pst1e",
            "pst1f",
            "pst1g",
            "pst1h",
            "pst1i",
            "pst1j",
          ],
        },
        {
          h2: "policyServiceTitle2",
          list: [
            "pst2a",
            "pst2b",
            "pst2c",
            "pst2d",
            "pst2e",
            "pst2f",
            "pst2g",
            "pst2h",
            "pst2i",
            "pst2j",
            "pst2k",
            "pst2l",
          ],
        },
        {
          h2: "policyServiceTitle3",
          list: ["pst3a", "pst3b", "pst3c"],
        },
        {
          h2: "policyServiceTitle4",
          list: [
            "pst4a",
            "pst4b",
            "pst4c",
            "pst4d",
            "pst4d1",
            "pst4d2",
            "pst4d3",
            "pst4e",
            "pst4f",
          ],
        },
        {
          h2: "policyServiceTitle5",
          list: [
            "pst5a",
            "pst5b",
            "pst5c",
            "pst5d",
            "pst5e",
            "pst5f",
            "pst5g",
            "pst5h",
            "pst5i",
            "pst5j",
            "pst5k",
            "pst5k1",
            "pst5k2",
            "pst5k3",
            "pst5k4",
            "pst5k5",
            "pst5k6",
            "pst5k7",
            "pst5k8",
            "pst5k9",
            "pst5l",
          ],
        },

        //block
        {
          h2: "policyServiceTitle6",
          list: ["pst6a", "pst6b"],
        },
        {
          h2: "policyServiceTitle7",
          list: ["pst7a"],
        },
        {
          h2: "policyServiceTitle8",
          list: ["pst8a"],
        },
        {
          h2: "policyServiceTitle9",
          list: ["pst9a", "pst9b", "pst9c", "pst9d", "pst9e"],
        },
        {
          h2: "policyServiceTitle10",
          list: [
            "pst10a",
            "pst10a1",
            "pst10a2",
            "pst10a3",
            "pst10a4",
            "pst10a5",
            "pst10a6",
            "pst10a7",
            "pst10a8",
            "pst10a8a",
          ],
        },

        //block
        {
          h2: "policyServiceTitle11",
          list: ["pst11a", "pst11b", "pst11c", "pst11d", "pst11e", "pst11f", "pst11g"],
        },
        {
          h2: "policyServiceTitle12",
          list: ["pst12a", "pst12b", "pst12c", "pst12d"],
        },
        {
          h2: "policyServiceTitle13",
          list: ["pst13a", "pst13b", "pst13c", "pst13d", "pst13e"],
        },
        {
          h2: "policyServiceTitle14",
          list: [
            "pst14a",
            "pst14b",
            "pst14c",
            "pst14d",
            "pst14d1",
            "pst14d2",
            "pst14d3",
            "pst14e",
            "pst14f",
            "pst14g",
            "pst14h",
            "pst14i",
            "pst14j",
          ],
        },
        {
          h2: "policyServiceTitle15",
          list: ["pst15a", "pst15b", "pst15c", "pst15d", "pst15e", "pst15f"],
        },

        //block
        {
          h2: "policyServiceTitle16",
          list: [
            "pst16a",
            "pst16b",
            "pst16c",
            "pst16d",
            "pst16e",
            "pst16f",
            "pst16g",
            "pst16h",
            "pst16i",
          ],
        },
        {
          h2: "policyServiceTitle17",
          list: ["pst17a"],
        },
        {
          h2: "policyServiceTitle18",
          list: ["pst18a", "pst18b", "pst18c", "pst18d"],
        },
        // {
        //   h2: "policyServiceTitle19",
        //   list: ["pst19a", "pst19b"],
        // },
        {
          h2: "policyServiceTitle20",
          list: ["pst20a", "pst20b"],
        },
      ],
    },
  },
  {
    id: 5,
    name: "privacyInfo",
    data: {
      h1: "privacyInfo",
      list: [
        {
          h2: "",
          list: [""],
        },
      ],
    },
  },
  // {
  //   id: 6,
  //   name: "helpInfo",
  //   data: {
  //     h1: "helpInfo",
  //     list: [
  //       {
  //         h2: "",
  //         list: [""],
  //       },
  //     ],
  //   },
  // },
  {
    id: 7,
    name: "cardHolder",
    data: {
      h1: "cardHolderTitle",
      list: [
        {
          h2: "",
          list: [
            "pht1a",
            "pht1b",
            "pht1c",
            "pht1c1",
            "pht1c2",
            "pht1c3",
            "pht1c4",
            "pht1d",
            "pht1e",
            "pht1f",
            "pht1g",
            "pht1h",
          ],
        },
      ],
    },
  },
]);

//动态导入国旗图片
const nationflag = ref();
// Object.keys(nationflag).forEach(key=>{
//   console.log('flag:',key);
// })

console.log("dynamic:", nationImgModal);
for (const key in nationImgModal) {
  // console.log('keyz:',key)
}
// console.log('dynamic:',nationflag.value)
</script>
<style lang="scss" scoped>
@import "./index.scss";

.tag{
  width: .3rem;
  height: 1.14rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  img{
    width: .12rem;
  }
  .taginfo{
    font-size: .1rem;
    font-weight: 400;
    writing-mode: vertical-lr;
  }
  
}
.top3tag{
    background: linear-gradient(to right, #44380c, #131721);
    .taginfo{
      color: #FF9D1E;
    }
  }
  .swipertag{
    background: linear-gradient(to right, #243055, #151721);
    .taginfo{
      color: #969FC4;
    }
  }
</style>
