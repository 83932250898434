import { createApp, provide, computed } from 'vue'
import './style.scss'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import { setupPinia } from './store'
import myModal from '@/components/common/modal/index.vue'
import empty from '@/components/common/empty/index.vue'
import customMessage from '@/lib/custom/scrollTipBar.js'
import showNotice from '@/components/common/notice/notice.js'
// import { loginModal } from "@/components/common/login/fnlogin.js";
import { loginModal } from "@/components/common/login";
// 标准的ES模块化引入方式
import mitt from 'mitt'
import MySocket from "@/request/socket.js"
import autoAuth from '@/utils/autoAuth.js'
// import VueLuckyCanvas from "@lucky-canvas/vue";
import backbar from "@/components/common/backbar/backbar.vue";
import eventEmitter from "@/utils/emitter.js";

// i18n
import i18n from './i18n'
const { t: $t, locale } = i18n.global;

// import store from "@/store/index.js"
import router from './router'
import { getImageUrlByNewURL } from '@/utils/getImageUrl.js';
import dimg from '@/utils/dynamicImgHandle.js'
import dname from '@/utils/dynamicAwardName.js'
import delST from '@/utils/delST.js'
import scroll from '@/utils/noscroll.js';
import { dynamicAwardColor,awardBgi,awardBgiResult } from '@/utils/awardQuality.js'
import DesignationInstruction from '@/utils/debounce.js';
// console.log('dy;',dynamicAwardColor);

const app = createApp(App);
// console.log('api:', import.meta.env.VITE_API_BASE_URL, import.meta.env.VITE_LANG);

DesignationInstruction(app)//给应用注册防抖指令
app.component('backbar', backbar)
//provide
app.provide('$getImageUrlByNewURL', getImageUrlByNewURL)
app.provide('scroll', scroll)

//globalProperties
app.config.globalProperties.$getImageUrlByNewURL = getImageUrlByNewURL
app.config.globalProperties.$dimg = dimg
app.config.globalProperties.$dname = dname
app.config.globalProperties.$delST = delST
app.config.globalProperties.$dynamicAwardColor = dynamicAwardColor.value//导入计算属性,js中要加.value
app.config.globalProperties.$dynamicAwardBgImg = awardBgi.value
app.config.globalProperties.$dynamicAwardBgImgResult = awardBgiResult.value
app.config.globalProperties.$cmessage = customMessage;
app.config.globalProperties.$eventEmitter = eventEmitter;
// app.config.globalProperties.$notice = showNotice;

// app.use(store)
setupPinia(app);
app.use(i18n)
app.use(router)
app.use(Antd)
app.component('myModal', myModal)
app.component('empty', empty)
app.use(MySocket) //连接websocket
// app.use(VueLuckyCanvas)
app.config.globalProperties.$notice = showNotice;
app.config.globalProperties.$EventBus = new mitt()
app.mount('#app');

router.beforeEach(async (to, from, next) => {
  // console.log('to:', to, 'from:', from, 'next:', next);
  if(to.name=="battle"&&from.name!="battleRoom"){
    // console.log('不保留对战key状态,清除对战tabkey状态');
    eventEmitter.emit("reset:battleTabKey");
  }
  // if(to.name=="battle"&&from.name=="battleRoom"){
  //   console.log('从对战房间详情返回到对战大厅');

  // }else{
  //   //重置对战tabkey=1
  //   eventEmitter.emit("reset:battleTabKey");
  // }

  const token = localStorage.getItem('token')
  // autoAuth()
  // console.log('tto ;',to);
  if (to.path.includes('/api/steam/handle')) {
    const response = await fetch(to.fullPath);
    // console.log('resp;', response);
    next('/box')
  }
  if (to.meta.needLogin) {
    // console.log("访问需要权限",token)
    if (token) {
      next()
    } else {
      // console.log('需要登录')
      eventEmitter.emit("api:un_auth");
      // loginModal({ visible: true }, (callnack) => {
      //   // console.log('未登录 弹登录框');
      //   callnack();
      // });

      // loginModal(
      //   { visible: true, title: $t('loginLarge'), contentType: "def" },
      //   (callnack) => {
      //     // console.log('未登录 弹登录框');
      //     callnack();
      //   }
      // );
      next({
        path: from.path || '/'
      })
    }
  } else {
    next()
  }
})
router.afterEach((to, from, next) => {
  // document.querySelector(".main-wrapper").scrollTop = 0;
  // window.scrollTo(0,0)
});

//改变font-size
(function (doc, win) {
  var docEI = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientataionchange' : 'resize',
    recalc = function () {
      var clientWidth = docEI.clientWidth;
      if (!clientWidth) return;
      //100是字体大小，1536是开发时浏览器窗口的宽度，等比计算
      docEI.style.fontSize = 100 * (clientWidth / 1920) + 'px';
      // console.log('绑定监听事件，dom加载后和尺寸变化时改变font-size',docEI.style.fontSize);
    }

  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);
