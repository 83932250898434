import axios from 'axios'
import { useCookies } from "vue3-cookies"
import * as auth from '@/utils/auth.js'
import showNotice from '@/components/common/notice/notice.js'
import pinia from '../store/pinia'
import { useLangStore } from "@/store/module/i18n.js";
import eventEmitter from "@/utils/emitter.js";
import { getLang } from "@/utils/auth";
const lanStore = useLangStore(pinia);//创建独立的pinia 无法和vue全局pinia同步响应  因为是独立的pinia
// console.log('langval;',lanStore.language);

const langArr = [
  {
    id: 1,
    apiKey: 'en',
    key: 'en-gb',
    desc: '英文'
  }, {
    id: 2,
    apiKey: 'zh-TW',
    key: 'zh-tw',
    desc: '繁體中文'
  },
  {
    id: 3,
    apiKey: 'zh-CN',
    key: 'zh-cn',
    desc: '简体中文'
  },
  {
    id: 4,
    apiKey: 'pl',
    key: 'pl',
    desc: '波兰语'
  },
  {
    id: 5,
    apiKey: 'ru',
    key: 'ru',
    desc: '俄罗斯语'
  },
  {
    id: 6,
    apiKey: 'fr',
    key: 'fr',
    desc: '法语'
  },
  {
    id: 7,
    apiKey: 'jp',
    key: 'jp',
    desc: '日语'
  },
  {
    id: 8,
    apiKey: 'de',
    key: 'de',
    desc: '德语'
  },
  {
    id: 9,
    apiKey: 'vn',
    key: 'vn',
    desc: '越南文'
  },
  {
    id: 10,
    apiKey: 'kr',
    key: 'kr',
    desc: '韩文'
  },
 
]
// import sp from '@/utils/sp.js'
const { cookies } = useCookies();
// console.log('api index.js:');
// const modules = import.meta.glob('./*.js')
// console.log('modules',modules);

const options = {
  // 显示操作成功消息 默认不显示
  showSuccess: false,
  // 成功提醒 默认使用后端返回值
  successMsg: '',
  // 显示失败消息 默认显示
  showError: true,
  // 失败提醒 默认使用后端返回信息
  errorMsg: '',
  // 显示请求时loading模态框 默认显示
  showLoading: true,
  // loading提醒文字
  loadingMsg: '加载中',
  // 需要授权才能请求 默认放开
  auth: false,
  // ...
};
const instance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  timeout: 30000,
  // maxRedirects:0,
  custom: options,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  }
})


// Loading全局实例
let LoadingInstance = {
  target: null,
  count: 0,
};
/**
 * 关闭loading
 */
function closeLoading() {
  if (LoadingInstance.count > 0) LoadingInstance.count--;
  //写一个全局加载动效,然后此处调用关掉动效方法
  // if (LoadingInstance.count === 0) sp.value=false;
}

// baidu
let geturl = window.location.href
let getqyinfo = geturl.split('?')[1]
let getqys = new URLSearchParams('?' + getqyinfo)
let get_bd_vid = getqys.get('bd_vid')
if (get_bd_vid) {
  cookies.set('bd_vid', get_bd_vid, '1d')
}
// console.log('bdvid:', get_bd_vid);

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // console.log('local langval;',getLang());
    // console.log('config:', config);
    // 在发送请求之前做些什么
    // 根据请求的某个属性来决定baseURL
    if (config.rss) {
      config.baseURL = 'https://rss.cs9skin.com/api/rss/index';
    }
    if (config.url == '/steam/login') {
      config.maxRedirects = 0
    }


    //接口需要登录  &&  用户没登录
    if (config.custom.auth && !auth.getToken()) {
      console.log('请登录后访问');
      //弹登录框
      return Promise.reject('请登录后访问')
    }
    if (config.custom.showLoading) {
      LoadingInstance.count++;
      // console.log('need show loading');
      // sp.value = true
      LoadingInstance.count === 1 //  此处调用全局加载动效
      //  console.log('req sp;',sp.value);
    }
    // console.log('ttt;',JSON.parse(getLang()));
    if (JSON.parse(getLang())) {
      config.headers.lang = JSON.parse(getLang()).language
    } else {
      config.headers.lang = localStorage.getItem('lang') || import.meta.env.VITE_LANG
    }
    // console.log('config.headers.lang:',config.headers.lang);
    // config.headers['Access-Control-Allow-Origin'] = '*';
    const token = auth.getToken()
    if (token && token != null) {
      //请求头携带token
      config.headers.Authorization = "Bearer" + token
      // console.log("已携带请求头-headers:",config.headers)
    }
    // config.headers['Cookie']=`bd_vid=${bd_vid}`,
    // config.headers.Cookie = `bd_vid=${get_bd_vid}`,
    config.headers.Bdvid = cookies.get('bd_vid')

    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)
// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // console.log('resp:', response);
    // sp.value = false
    const Authorization = response.headers.Authorization || response.headers.authorization
    //自动设置登录令牌
    if (Authorization) {
      // userstore 设置token
      auth.setToken(Authorization)
    }

    response.config.custom.showLoading && closeLoading()

    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if (response.status === 200) {
      if (response.data.code != 200) {
        if (response.data.code == 401) {
          console.log('移除');
          auth.removeToken()
          //监听 订阅的登录弹框事件
          // router.push("/")
          
          return Promise.resolve(response)
        } else {
          showNotice('f', { desc: response.data.message })
        }
      }
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    console.log('error;', error);
    // showNotice('f', { desc: error.message })
    //bug 配置请求超时
    console.log(error.code == 'ERR_NETWORK');
    if (error.code == 'ERR_NETWORK') {
      console.log('-----');
      showNotice('f', { desc: error.message })
      // return
      if (error.config.url == 'https://api.skinroll.net/api/steam/login') {
        return window.location.href = "https://steamcommunity.com/openid/login?openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&openid.mode=checkid_setup&openid.return_to=http%3A%2F%2Fapi.skinroll.net%2Fapi%2Fsteam%2Fhandle&openid.realm=http%3A%2F%2Fapi.skinroll.net&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select";
      }
      // notification.open({
      //   message:'网络已断开,请检查网络是否连接'
      // })
      // showMessage('网络已断开,请检查网络是否连接')
    }
    console.log('-==:',);

    if(error.code=='"ECONNABORTED"'){
      console.log('请求超时');
      showNotice('f', { desc: error.message })
    }

    if (error.response.status == 500) {
      showNotice('f', { desc: error.response.data.message })
      console.log("服务器错误:", error.response);
    } else {
      auth.removeToken()
      eventEmitter.emit("api:redirect")
      // router.push("/")
      // location.reload()
    }
    return Promise.reject('error')
  }
)



export default instance