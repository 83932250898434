import enGb from './en-gb.json'
import zhCn from './zh-cn.json'
import zhTw from './zh-tw.json'
import de from './de.json'
import fr from './fr.json'
import pl from './pl.json'
import jp from './jp.json'
import ru from './ru.json'
import kr from './kr.json'
import vn from './vn.json'



export default{
  'en-gb':enGb,
  'zh-cn':zhCn,
  'zh-tw':zhTw,
  'de':de,
  'fr':fr,
  'pl':pl,
  'jp':jp,
  'ru':ru,
  'kr':kr,
  'vn':vn
}