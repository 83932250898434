//防抖函数
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

/**
 * 注册指令-函数防抖
 * @param {Vue} app vue应用实例
 */
export default function DesignationInstruction(app) {
  app.directive('debounce', {
    beforeMount(el, binding) {
      if (typeof binding.value !== 'function') {
        throw new Error('v-debounce value must be a function');
      }
      const wait = binding.arg || 300; // 默认等待时间为300ms
      const debouncedFn = debounce(binding.value, wait);
      el.addEventListener('click', debouncedFn);
      // 存储在el上，以便在unmounted时移除
      el._debouncedFn = debouncedFn;
    },
    unmounted(el) {
      el.removeEventListener('click', el._debouncedFn);
      delete el._debouncedFn;
    }
  });
}